import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'base',
    props: (route) => ({
      acr_values: route.query.acr_values,
      tenantId: route.query.tenantId,
      companyCode: route.query.companyCode,
      startpage: route.query.startpage,
      meetingsId: route.query.meetingsId,
      meetingsActionId: route.query.meetingsActionId,
      loginHint: route.query.login_hint
    }),
    component: () => import('@/components/Login.vue')
  },
  {
    path: '/error/:message',
    name: 'error',
    props: true,
    component: () => import('@/components/Error.vue')
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

export default router
