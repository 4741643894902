import { computed, ComputedRef } from 'vue'

export interface Language {
  [key: string]: string | number
  something_went_wrong: string
  back: string
  email: string
  next: string
  no_organisation_found: string
  no_database_found: string
  welcome_to_stratsys: string
  sign_in_to_your_account: string
  contact_us: string
  no_product: string
}

const swedish: Language = {
  something_went_wrong: 'Något gick fel',
  back: 'Tillbaka',
  email: 'E-post',
  next: 'Nästa',
  no_organisation_found: 'Vi hittade inget konto som matchar denna e-post',
  no_database_found: 'Vi hittade ingen databas kopplad till din användare',
  welcome_to_stratsys: 'Välkommen till Stratsys',
  sign_in_to_your_account: 'Logga in på ditt konto',
  contact_us: 'Om du inte lyckas lösa detta problem, kontakta oss då på:',
  no_product: 'Hoppsan! Du verkar inte ha åtkomst till någon produkt i Stratsys.'
}

const english: Language = {
  something_went_wrong: 'Something went wrong',
  back: 'Back',
  email: 'E-mail',
  next: 'Next',
  no_organisation_found: "We couldn't find an account matching this email",
  no_database_found: "We couldn't find a database connected to your user",
  welcome_to_stratsys: 'Welcome to Stratsys',
  sign_in_to_your_account: 'Sign in to your account',
  contact_us: 'If you do not succeed in solving this problem, please contact us at:',
  no_product: "Oops! You don't seem to have access to any product in Stratsys."
}

const norwegian: Language = {
  something_went_wrong: 'Noe gikk galt',
  back: 'Tilbake',
  email: 'E-mail',
  next: 'Neste',
  no_organisation_found: 'Vi fant ingen konto som samsvarer med denne e-postadressen',
  no_database_found: 'Vi fant ingen database knyttet til brukeren din',
  welcome_to_stratsys: 'Velkommen til Stratsys',
  sign_in_to_your_account: 'Logg inn på kontoen din',
  contact_us: 'Hvis du ikke klarer å løse dette problemet. Kontakt oss på:',
  no_product: 'Du ser ikke ut til å ha tilgang til noe produkt i Stratsys.'
}

const currentLanguage = computed(() => navigator.language.substring(0, 2).toLowerCase())

const language: ComputedRef<Language> = computed(() => {
  switch (currentLanguage.value) {
    case 'sv':
      return swedish as Language
    case 'nb':
      return norwegian as Language
    default:
      return english as Language
  }
})

export const useLanguage = () => {
  return {
    language,
    currentLanguage
  }
}
